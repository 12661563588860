import React from 'react'
import Faq from '../components/common/Faq.js'
import { Map, MapOptions } from '../components/contact/Map'
import Contact from '../components/contact/Contact'
import NavBar from '../components/common/NavBar'
import Layout from '../layouts';
import { graphql } from "gatsby"

const ContactPage = ({ data, location }) => {
  const faqs = data._faqs.edges.map(e => ({
    question: e.node.question,
    answer: e.node.answer,
    id: e.node.id,
  }))

  const contact = data._contact
  const mailingConfig = data._mailing
  const bgImage = data.contactBgImage
  const contactAvatar = data.contactAvatar

  //TODO remove margin override after resolving the scss problem
  return (
    <Layout>
      <NavBar   
        // style={{ marginBottom: '-98px' }}
        transparentBackground
        lightTheme
        phoneNumber={contact.contact.phone}
        email={contact.contact.email}
        location={location}
      />
      <Contact data={contact} bgImage={bgImage} contactAvatar={contactAvatar} mailingConfig={mailingConfig} />
      <Map
        location={MapOptions.zaven.global.location}
        zoom={MapOptions.zaven.global.zoom}
        marker={MapOptions.zaven.marker}
        width="100%"
        height="320px"
      />
      <Faq listOfFaqs={faqs} />
    </Layout>
  )
}

export default ContactPage

export const contactQuery = graphql`
  query ContactQuery {
    contactBgImage: imageSharp(fluid: { originalName: { regex: "/contact_bg/" } }) {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
    contactAvatar: imageSharp(fluid: { originalName:  { regex: "/zaven_www_team_piotr_300x300/" } }) {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
    _contact: dataJson {
      ...Contact
    }
    _faqs: allFaqJson {
      ...Faqs
    }
  }
`
